import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  { path: '', redirectTo: '/home', pathMatch: 'full' },
  { path: 'hr-dashboard', loadChildren: () => import('./hr-dashboard/hr-dashboard.module').then(m => m.HrDashboardModule) },
  { path: 'assessment', loadChildren: () => import('./assessment/assessment.module').then(m => m.AssessmentModule) },
  { path: 'survey', loadChildren: () => import('./exit-survey/exit-survey.module').then(m => m.ExitSurveyModule) },
  { path: 'home', loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule) },
  // Fallback when no prior route is matched
  { path: '**', redirectTo: '/home', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules, scrollPositionRestoration: 'enabled'  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
