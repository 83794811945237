import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable, switchMap } from 'rxjs';
import { AuthService } from '../services/auth.service';

@Injectable()
export class SetLoginTokenInterceptor implements HttpInterceptor {

  constructor(private authService: AuthService) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return this.authService.token$.pipe(
      switchMap(auth => {
        if (auth?.token) {
          request = request.clone({
            setHeaders: {
              'authorization': auth.token
            }
          });
        }
        return next.handle(request);
      })
    )
  }
}
