import { Component, Inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DOCUMENT } from '@angular/common';
import { Subscription, filter, merge } from 'rxjs';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { AppTheme } from './core/enum/app-theme.enum';
import { pipeToActivatedRoute } from './core/utility/pipe-to-activated-route.util';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  subscription = new Subscription();

  constructor(
    private translate: TranslateService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    @Inject(DOCUMENT) private document: Document,
  ){
    this.translate.addLangs(['en','th']);
    this.translate.setDefaultLang('en');
    this.translate.use('th');
    this.translate.use('en');
    this.document.documentElement.lang = 'en';
    const onNavigationEnd = this.router.events.pipe(filter(event => event instanceof NavigationEnd));


    // Change page title on navigation or language change, based on route data
    this.subscription.add(
      pipeToActivatedRoute(merge(this.translate.onLangChange, onNavigationEnd), this.activatedRoute)
        .subscribe((data) => {
          this.document.body.classList.remove(...[AppTheme.default, AppTheme.white]);
          if (data.theme) {
            this.document.body.classList.add(data.theme);
          } else {
            this.document.body.classList.add(AppTheme.default);
          }
        })
    );
  }


}
