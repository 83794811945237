export const appConfig = {
  picDefault: '/assets/images/avatar_default.svg',
  timeoutAfterInit: 500,
  timeoutAfterGraphInit: 1200,
  redirectDebounceTime: 10000,
  showDelayTooltip: 400,
  snackBarDuration: 8000,
  debounceTime: 800,
  limitAutoComplete: 10,
  ageTo: 66,
  ageFrom: 16,
  workTenureFrom: 0,
  workTenureTo: 50,
  delayCreateSuccessSnackBar: 4000,
  supportedUploadFormatList: ['csv'],
  maxFileSizeMB: 10,
  assessmentId: 8,
  contactEmail: 'customerservice@scoutout.net',
  contactPhone: '022382639'
};