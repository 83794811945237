import { Injectable } from '@angular/core';
import { Auth, IdTokenResult, User, checkActionCode, confirmPasswordReset, onAuthStateChanged, onIdTokenChanged, sendEmailVerification, sendPasswordResetEmail, signInWithEmailAndPassword, signOut, verifyPasswordResetCode } from '@angular/fire/auth';
import { BehaviorSubject, Observable } from 'rxjs';
import { UserInfo } from '../model/user.model';
import { UserService } from './user.service';
import { LoginData } from '../model/login-data.model';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private currentUserStore = new BehaviorSubject<User | null | undefined>(undefined);
  currentUser$ = this.currentUserStore.asObservable();
  private userInfo = new BehaviorSubject<UserInfo | null | undefined>(undefined);
  userInfo$ = this.userInfo.asObservable();
  private token = new BehaviorSubject<IdTokenResult | null | undefined>(undefined);
  token$ = this.token.asObservable();

  constructor(
    private auth: Auth,
    private userService: UserService,) {
    onIdTokenChanged(this.auth, (user) => {
      if (user) {
        user.getIdTokenResult().then(tokenResult => {
          this.token.next(tokenResult);
        });
      }
    })
    onAuthStateChanged(this.auth, (user) => {
      if (user?.uid) {
        this.userService.getUser(user.uid).then(userSnapshot => {
          userSnapshot.forEach(_user => {
            this.userInfo.next(_user.data());
          });
        });
      }
      this.currentUserStore.next(user);
    });
  }

  refreshCurrentUser() {
    return new Observable(subscribe => {
      onAuthStateChanged(this.auth, (user) => {
        if (user) {
          user.reload().then(() => {
            this.currentUserStore.next(user);
            subscribe.next(user);
          });
        } else {
          this.currentUserStore.next(user);
          subscribe.next(user);
        }
      });
    });
  }

  login({ email, password }: LoginData) {
    return signInWithEmailAndPassword(this.auth, email, password);
  }

  forgotPassword(email: string) {
    return sendPasswordResetEmail(this.auth, email);
  }

  reSendEmailVerification(user: User) {
    return sendEmailVerification(user);
  }

  confirmResetPassword(oobCode: string, newPassword: string) {
    return confirmPasswordReset(this.auth, oobCode, newPassword);
  }

  verifyResetCode(oobCode: string) {
    return verifyPasswordResetCode(this.auth, oobCode);
  }

  verifyEmail(oobCode: string) {
    return checkActionCode(this.auth, oobCode);
  }

  logout() {
    return signOut(this.auth).then(() => {
      this.currentUserStore.next(null);
    });
  }
}
