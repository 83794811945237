import { ActivatedRoute } from '@angular/router';
import { Observable, filter, map, mergeMap } from 'rxjs';

export function pipeToActivatedRoute(obs: Observable<any>, activatedRoute: ActivatedRoute) {
  return obs.pipe(
    map(() => {
      let route = activatedRoute;
      while (route.firstChild) {
        route = route.firstChild;
      }
      return route;
    }),
    filter((route: ActivatedRoute) => route.outlet === 'primary' || route.outlet === 'content'),
    mergeMap((route: ActivatedRoute) => route.data)
  );
}