import { Injectable } from '@angular/core';
import { collection, doc, Firestore, getDocs, limit, query, updateDoc, where } from '@angular/fire/firestore';
import { appConfig } from '../config/main.config';
import { UserInfo } from '../model/user.model';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  private readonly tableName = 'user';

  private allUser = new BehaviorSubject<UserInfo[] | null>(undefined);
  allUser$ = this.allUser.asObservable();

  constructor(private firestore: Firestore) {
  }

  getUser(uid: string) {
    const myCollection = collection(this.firestore, this.tableName);
    const queryRef = query(myCollection, where('userUid', '==', uid));
    return getDocs(queryRef);
  }

  getUserList(email?: string, excludeEmails?: string[]) {
    const myCollection = collection(this.firestore, this.tableName);
    let queryRef;
    if (!email) {
      queryRef = query(myCollection, where('email', 'not-in', excludeEmails), limit(appConfig.limitAutoComplete));
    } else {
      queryRef = query(myCollection, where('email', '>=', email), where('email', '<', email), where('email', 'not-in', excludeEmails));
    }
    return getDocs<UserInfo, UserInfo>(queryRef);
  }

  async getAllUser() {
    const myCollection = collection(this.firestore, this.tableName);
    const queryRef = query(myCollection);
    const usersRef = await getDocs(queryRef);
    const users = [];
    usersRef.forEach(doc => {
      const data = doc.data();
      users.push({ ...data, id: doc.id })
    });
    this.allUser.next(users);
  }

  updateAssessmentLink(userUid, assessment) {
    const myCollection = collection(this.firestore, this.tableName);
    const userRef = doc(myCollection, userUid);
    return updateDoc(userRef, { assessmentLink: assessment });
  }
}
