export const environment = {
  production: true,
  googleAnalytics4TrackingID: '',
  rootUrl: 'https://scoutout-assessment-demo.web.app/',
  firebase: {
    apiKey: "AIzaSyCQZ5Yta2TnspIBSo9N0KADnrGtN0jUO-g",
    authDomain: "scoutout-assessment-demo.firebaseapp.com",
    projectId: "scoutout-assessment-demo",
    storageBucket: "scoutout-assessment-demo.appspot.com",
    messagingSenderId: "348891922465",
    appId: "1:348891922465:web:c6225f31bea2215477a360"
  },
  api: {
    exportSurveyToEmail: 'https://asia-southeast1-scoutout-assessment-demo.cloudfunctions.net/sendExitSurveyCsv',
    exportAssessmentToEmail: 'https://asia-southeast1-scoutout-assessment-demo.cloudfunctions.net/sendAssessmentCsv',
    getSurveyList: 'https://asia-southeast1-scoutout-assessment-demo.cloudfunctions.net/surveyPagination',
    getSurveyResultGraph: 'https://asia-southeast1-scoutout-assessment-demo.cloudfunctions.net/surveyResult',
    getAssessmentPagination: 'https://asia-southeast1-scoutout-assessment-demo.cloudfunctions.net/assessmentPagination',
    importEmployees: 'https://asia-southeast1-scoutout-assessment-demo.cloudfunctions.net/importSurveyEmployee'
  },
  isDemo: true
};

